/**
 * Copyright (C) SiteVision AB 2002-2017, all rights reserved
 *
 * @author albin
 */
define(function(require) {
   'use strict';

   var
      _           = require('underscore'),
      WebApp      = require('WebApp'),
      AppRegistry = window.AppRegistry;

   return {

      start: function(registry) {
         registry = registry || AppRegistry.getRegistry();
         AppRegistry.setAppStarter(this);

         _.each(registry, function(application) {
            _.each(application.instances, function(context) {
               var instance = new WebApp(context, application.bundle, AppRegistry.getBootstrapData(context.portletId));

               _.each(application.modules, function(module, path) {
                  module.module(instance.createDefine(path));
               });

               instance.start();
            });
         });
      }
   };
});