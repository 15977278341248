/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author albin
 */
define(function(require) {
   'use strict';

   var
      $ = require('jquery'),
      _ = require('underscore');

   function unbindEventsRecursively(subComponents) {
      subComponents.forEach(function(subComponent) {
         subComponent._unbindEvents();

         if (subComponent._subComponents && subComponent._subComponents.length) {
            unbindEventsRecursively(subComponent._subComponents);
         }
      });
   }

   function create(Component, require, store) {
      return Component.extend({

         addItem: function(item, options) {
            var childComponent = this._createChildComponent(item, options && options.options);

            this.$el[options && options.prepend ? 'prepend' : 'append'](childComponent.render().$el);
         },

         _createChildComponent: function(state, options) {
            if (!this.childComponentPath) {
               throw new Error('No child component specified');
            }

            if (!this.ChildComponent) {
               this.ChildComponent = require('/component/' + this.childComponentPath);
            }

            options = _.extend({}, options, {id: state.id});
            state = _.extend(state, this.ChildComponent.prototype.filterState(store.getState(), options));

            var childComponent = new this.ChildComponent(state, { options: options });

            this._subComponents.push(childComponent);

            return childComponent;
         },

         triggerChildren: function(e, data) {
            _.each(this._subComponents, function(subComponent) {
               subComponent.trigger(e, data);
            });
         },

         getChildAt: function(index) {
            return this._subComponents[index];
         },

         getChildById: function(id) {
            return _.find(this._subComponents, function(child) {
               return child.state.id === id;
            });
         },

         removeChildById: function(id) {
            var childToRemove = this.getChildById(id);

            if (childToRemove) {
               this._subComponents = _.filter(this._subComponents, function(child) {
                  return child !== childToRemove;
               });

               childToRemove.destroy();
            }
         },

         render: function() {
            var $temp = $('<div></div>'),
               className = _.result(this, 'className'),
               attributes = _.result(this, 'attributes'),
               childOptions = _.result(this, 'childOptions', {});

            unbindEventsRecursively(this._subComponents);
            this._subComponents = [];
            this._ensureEl();

            this.$el.removeClass();
            if (className) {
               this.$el.addClass(className);
            }

            if (attributes) {
               this.$el.attr(attributes);
            }

            _.each(this.state[this.childProperty], function(child) {
               $temp.append(this._createChildComponent(child, childOptions).render().$el);
            }, this);

            this.$el.html('').append($temp.children());

            this.isRendered = true;
            this.triggerMethod('rendered');
            this.triggerMethod('attached');

            return this;
         }
      });
   }

   return {
      create: create
   };
});
