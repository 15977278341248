/**
 * Copyright (C) SiteVision AB 2002-2017, all rights reserved
 *
 * @author albin
 */
define(function(require) {
   'use strict';

   var
      _     = require('underscore'),
      Class = require('class.extend');

   function getLanguageFromLocale(locale) {
      var localeParts = locale.split('-');

      return localeParts[0];
   }

   function populateBundleWithDefaults(locale, defaultLocale, bundles) {
      var bundle = _.extend({}, bundles[defaultLocale]);

      if (locale === defaultLocale) {
         return bundle;
      }

      var candidate,
         appLanguage = getLanguageFromLocale(locale).toLowerCase();

      _.find(_.keys(bundles), function(key) {
         var language = getLanguageFromLocale(key).toLowerCase();

         if (appLanguage === language) {
            candidate = bundles[key];

            if (locale === key) {
               return true;
            }
         }
      });

      if (candidate) {
         _.extend(bundle, candidate);
      }

      return bundle;
   }

   return Class.extend({

      init: function(locale, defaultLocale, bundles) {
         this.bundle = populateBundleWithDefaults(locale, defaultLocale, bundles);
      },

      get: function(key) {
         if (arguments.length === 1) {
            return this.bundle[key] || key;
         }

         var args = Array.prototype.slice.call(arguments, 1),
            res = this.bundle[key];

         for (var i = 0; i < args.length; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');

            res = res.replace(reg, args[i]);
         }

         return res || key;
      }
   });
});
