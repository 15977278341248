/**
 * Copyright (C) SiteVision AB 2002-2019, all rights reserved
 *
 * @author albin
 */
define(function(require) {
   'use strict';

   var
      _        = require('underscore'),
      Class    = require('class.extend'),
      events   = require('events');

   function getChangedQueryParameterKeys(oldQueryStringObject, newQueryStringObject) {
      var changed = [];

      _.each(newQueryStringObject, function(value, key) {
         var oldValue = oldQueryStringObject[key];

         if (oldValue !== value) {
            changed.push(key);
         }
      });

      _.each(oldQueryStringObject, function(value, key) {
         if (!newQueryStringObject.hasOwnProperty(key)) {
            changed.push(key);
         }
      });

      return changed;
   }

   function getQueryStringAsObject(queryString, pathParameterName) {
      var pairs = queryString.length ? queryString.slice(1).split('&') : [],
         result = {};

      pairs.forEach(function(pair) {
         pair = pair.split('=');
         result[pair[0]] = decodeURIComponent(pair[1] || '');
      });

      return _.omit(result, 'sv.target', pathParameterName);
   }

   function getPathFromUrl(url, pathParameterName) {
      var queryString = url.substring(url.indexOf('?') + 1),
         params = queryString.split('&'),
         routeParam = _.find(params, function(param) {
            return param.indexOf(pathParameterName) === 0;
         }),
         path = routeParam ? routeParam.split('=')[1] : '/';

      return decodeURIComponent(path);
   }

   function getQueryString(queryParamsObject) {
      var result = [];

      _.each(queryParamsObject, function(value, key) {
         result.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
      });

      return result.join('&');
   }

   return Class.extend(_.extend({

      init: function(options) {
         this.history = window.history;
         this.location = window.location;
         this.portletId = options.portletId;

         this.pathParameterName = 'sv.' + this.portletId + '.route';
         this.currentQueryString = this.location.search;
         this.currentPath = getPathFromUrl(this.location.href, this.pathParameterName);

         window.addEventListener('popstate', this._handleRouteChange.bind(this), false);
      },

      _handleRouteChange: function() {
         var path = getPathFromUrl(this.location.href, this.pathParameterName),
            newQueryStringAsObject = getQueryStringAsObject(this.location.search, this.pathParameterName),
            oldQueryStringAsObject = getQueryStringAsObject(this.currentQueryString, this.pathParameterName),
            changedQueryParamKeys = getChangedQueryParameterKeys(oldQueryStringAsObject, newQueryStringAsObject);

         if (path !== this.currentPath) {
            this.currentPath = path;

            this.trigger('path:changed', {
               path: path,
               url: this.location.search
            });
         }

         this.currentQueryString = this.location.search;

         if (changedQueryParamKeys.length) {
            var eventOptions = {
               queryParams: newQueryStringAsObject,
               url: this.location.search
            };

            _.each(changedQueryParamKeys, function(key) {
               this.trigger('query:changed:' + key, eventOptions);
            }, this);

            if (changedQueryParamKeys.length) {
               this.trigger('query:changed', eventOptions);
            }
         }
      },

      getUrl: function(path, queryParams) {
         var realPath = _.isEmpty(path) ? getPathFromUrl(this.location.search, this.pathParameterName) : path,
            url = '?sv.target=' + this.portletId + '&' + this.pathParameterName + '=' + realPath;

         _.each(queryParams, function(value, key) {
            url += '&' + (value === true ? encodeURIComponent(key) : encodeURIComponent(key) + '=' + encodeURIComponent(value));
         });

         return url;
      },

      getStandaloneUrl: function(path, queryParams) {
         var pageId = window.sv.PageContext.pageId;

         if (!pageId) {
            return '/';
         }

         var url = '/appresource/' +  pageId + '/' + this.portletId + path;

         if (queryParams) {
            url += '?' + getQueryString(queryParams);
         }

         return url;
      },

      parseQueryString: function() {
         return getQueryStringAsObject(this.location.search, this.pathParameterName);
      },

      navigate: function(url, options) {
         var path = _.isEmpty(url) ? getPathFromUrl(this.location.href, this.pathParameterName) : getPathFromUrl(url, this.pathParameterName),
            queryParams = options && options.queryParams,
            replace = options && options.replace,
            newUrl = this.getUrl(path, queryParams),
            newQueryStringAsObject = getQueryStringAsObject(newUrl, this.pathParameterName),
            oldQueryStringAsObject = getQueryStringAsObject(this.location.search, this.pathParameterName),
            changedQueryParamKeys = getChangedQueryParameterKeys(oldQueryStringAsObject, newQueryStringAsObject);

         this.history[replace ? 'replaceState' : 'pushState'](
            {},
            document.title,
            newUrl
         );

         if (path !== this.currentPath) {
            this.currentPath = path;

            this.trigger('path:changed', {
               path: path,
               url: newUrl
            });
         }

         this.currentQueryString = newUrl;

         if (changedQueryParamKeys.length) {
            var eventOptions = {
               queryParams: newQueryStringAsObject,
               url: newUrl
            };

            _.each(changedQueryParamKeys, function(key) {
               this.trigger('query:changed:' + key, eventOptions);
            }, this);

            this.trigger('query:changed', eventOptions);
         }
      }
   }, events));
});