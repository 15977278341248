/**
 * Copyright (C) Sitevision AB 2002-2021, all rights reserved
 *
 * @author eric
 */

define(function () {
   'use strict';

   const _ = require('underscore');

   const BASE_OPTIONS = {
      type: ''
   };

   return {

      /**
      *  Valid option properties
      *  "type" : success, primary or ''
      *  "ttl" : (time to live) in seconds for toast to be shown
      *  "callback" : a callback function executed when toast is removed
      *  "checkmark" : boolean value if checkmark should be shown
      *  "heading" : a header string, (not required), will be <strong>
      *  "message" : a message string
      */
      publish: function (options) {
         try {
            const event = new CustomEvent('sv-publish-toast',
               {
                  detail: _.extend({}, BASE_OPTIONS, options)
               }
            );

            document.dispatchEvent(event);
         } catch (e) {
            window.console.log('Something went wrong! Failed to create Toast!');
         }
      }
   };
});
