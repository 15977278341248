/**
 * Copyright (C) Sitevision AB 2002-2021, all rights reserved
 *
 * @author albin
 */

define(function () {
   'use strict';

   return {
      csrf: {
         getToken: function() {
            var token = window.sv.PageContext.csrfToken;

            return token || null;
         },
         getParameterName: function() {
            return 'sv.csrfToken';
         },
         getHeaderName: function() {
            return 'X-CSRF-Token';
         }
      }
   };
});
