/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author albin
 */
define(function(require) {
   'use strict';

   var
      _              = require('underscore'),
      $              = require('jquery'),
      Class          = require('class.extend'),
      events         = require('events'),
      component      = require('Component'),
      listComponent  = require('ListComponent'),
      requester      = require('requester'),
      Router         = require('Router'),
      I18n           = require('i18n'),
      App            = require('App'),
      toasts         = require('toasts'),
      security       = require('./security'),
      redux          = require('redux'),
      AppRegistry    = window.AppRegistry;

   var REQUIRE_REG_EX = /[^.]\s*require\s*\(\s*["']([^'"\s]+)["']\s*\)/g,
      COMMENTS_REG_EX = /(\/\*([\s\S]*?)\*\/|([^:]|^)\/\/(.*)$)/mg,
      REACT_REG_EX = /^(react|react-dom)$/,
      globalEvents = _.extend({}, events);

   var dummyReducer = function(state) {
      return state;
   };

   var getExternalReact = function(version) {
      var versionArr = version.split('.');
      var major = versionArr[0];
      var minor = versionArr[1];

      return window.sv.UNSAFE_MAY_CHANGE_AT_ANY_GIVEN_TIME_webAppExternals['react_' + major + '_' + minor];
   };

   return Class.extend({

      init: function(context, bundle, bootstrapData) {
         this.requiredLibs = context.requiredLibs;
         this.app = new App(context);
         this.router = new Router(context);

         var i18nObj = new I18n(context.locale, context.defaultLocale, bundle);

         this.i18n = _.bind(i18nObj.get, i18nObj);
         this.i18n.get = _.bind(i18nObj.get, i18nObj); // This is to conform with the server api
         this.initialState = AppRegistry.getInitialState(this.app.portletId);
         this.store = redux.createStore(dummyReducer, this.initialState);
         this.agnosticRender = bootstrapData && bootstrapData.AGNOSTIC_RENDERER;

         var Component = component.create(context, _.bind(this.require, this), this.app, this.router, this.i18n, globalEvents, this.store);

         this.bootstrapData = bootstrapData;
         this.modules = {
            app: this.app,
            requester: requester,
            router: this.router,
            i18n: this.i18n,
            events: globalEvents,
            toasts: toasts,
            security: security,
            url: {
               get: this.router.getUrl.bind(this.router)
            }
         };

         if (!this.agnosticRender) {
            _.extend(this.modules, {
               underscore: _,
               jquery: $,
               Component: Component,
               ListComponent: listComponent.create(Component, _.bind(this.require, this), this.store),
               redux: redux,
               store: this.store
            });
         }

         this.definitions = {};
         this.definitionQue = [];
      },

      start: function() {
         this.startModules();

         if (this.agnosticRender) {
            var main = this.require('/main');
            var element = document.querySelector('[data-cid="' + this.app.portletId + '"]');

            if (main.default) {
               main.default(this.initialState, element);
            } else if (_.isFunction(main)) {
               main(this.initialState, element)
            }
         } else {
            this.setUpReducer();

            this.setUpComponents();
         }
      },

      setUpReducer: function() {
         var reducer = this.require('/reducer');

         if (reducer) {
            this.modules.store.replaceReducer(reducer);
         }
      },

      setUpComponents: function() {
         var loadedComponents = {},
            initialState = this.modules.store.getState(),
            childComponentStateExtractionStrategy = this.app.childComponentStateExtractionStrategy;

         _.each(this.bootstrapData, function(components, componentName) {
            var Component;

            if (componentName === 'main') {
               Component = this.require('/' + componentName);
            } else {
               Component = this.require('/component/' + componentName);
            }

            if (!Component) {
               window.console.error('Error in: ' + this.app.webAppId + '. Could not load component with name ' + componentName);
               return;
            }

            var counter = 0;

            _.each(components, function(bootstrapData, componentId) {
               var $el = $('[data-cid="' + componentId + '"]'),
                  state = Component.prototype.filterState(initialState, bootstrapData.options);

               if (childComponentStateExtractionStrategy === 'BY_PARENT_PROPERTY') { // Deprecated
                  if (bootstrapData.options && bootstrapData.options._parentProperty) {
                     var parentState = initialState[bootstrapData.options._parentProperty];

                     if (parentState) {
                        _.extend(state, parentState[counter++]);
                     }
                  }
               }

               loadedComponents[componentId] = new Component(state, {
                  $el: $el,
                  serverRendered: true,
                  cid: componentId,
                  subComponents: bootstrapData.subComponents,
                  options: bootstrapData.options
               });
            });
         }, this);

         this.app.trigger('components:loaded', loadedComponents);
      },

      require: function(path) {
         if (this.requiredLibs.react && REACT_REG_EX.test(path)) {
            return getExternalReact(this.requiredLibs.react)[path];
         }

         return this.modules[path];
      },

      define: function(path, deps, callback) {
         var usesRequireFunction = false;

         if (!callback) {
            callback = deps;
            deps = undefined;
            usesRequireFunction = true;
         }

         if (!deps && _.isFunction(callback)) {
            deps = [];
            if (callback.length) {
               callback
                  .toString()
                  .replace(COMMENTS_REG_EX, '')
                  .replace(REQUIRE_REG_EX, function(match, dep) {
                     deps.push(dep);
                  });
            }
         }

         this.definitions[path] = {
            path: path,
            callback: callback,
            deps: deps,
            usesRequireFunction: usesRequireFunction
         };

         this.definitionQue.push({
            path: path,
            deps: deps,
            callback: callback,
            usesRequireFunction: usesRequireFunction
         });
      },

      startModule: function(definition) {
         if (this.modules[definition.path]) {
            return;
         }

         definition.deps.forEach(function(dep) {
            var module;

            if (Object.keys(this.modules).indexOf(dep) !== -1) {
               return;
            }

            if (REACT_REG_EX.test(dep)) {
               return;
            }

            module = this.definitions[dep];
            if (!module) {
               window.console.error('Error in: ' + this.app.webAppId + '. Missing dependency "' + dep + '" in module ' + definition.path);
               return;
            }

            this.startModule(this.definitions[dep]);
         }, this);

         if (definition.usesRequireFunction) {
            /**
             * define(function (require) {
             *   const depA = require('depA');
             *   const depB = require('depB');
             *
             *   ...
             * })
             */
            this.modules[definition.path] = definition.callback(this.require.bind(this));
         } else {
            /**
             * define(['depA', 'depB'], function (depA, depB) {
             *   ...
             * })
             */
            this.modules[definition.path] = definition.callback.apply(null, definition.deps.map((function(dep) {
               if (this.requiredLibs.react && REACT_REG_EX.test(dep)) {
                  return getExternalReact(this.requiredLibs.react)[dep];
               }

               return this.modules[dep];
            }).bind(this)));
         }
      },

      startModules: function() {
         this.definitionQue.forEach(this.startModule, this);
      },

      createDefine: function(path) {
         return _.extend(this.define.bind(this, path), {amd: {jQuery: true}});
      }
   });
});
