/**
 * Copyright (C) SiteVision AB 2002-2023, all rights reserved
 *
 * @author albin
 */
define(function(require) {
   'use strict';

   var $ = require('jquery');
   var _ = require('underscore');
   var security = require('./security');

   function sendRequest(options) {
      var hasDataObject = false;

      if (options.fileUpload && /put|post|patch/i.test(options.type)) {
         options.processData = false;
         options.contentType = false;
      } else if (!options.data || _.isObject(options.data)) {
         hasDataObject = true;
         options.data = options.data || {};
         options.data.svAjaxReqParam = 'ajax'; // SV-23492 - https://stackoverflow.com/a/16152893/1397073
      }

      if (!/get/i.test(options.type) && hasDataObject && options.processData !== false) {
         options.processData = false;
         options.data = JSON.stringify(options.data);
      }

      // This is for clarity, the header will be added globally in "beforeSend" as well
      if (/put|post|patch|delete/i.test(options.type)) {
         var csrfToken = security.csrf.getToken();

         if (csrfToken) {
            options.headers = options.headers || {};
            options.headers[security.csrf.getHeaderName()] = csrfToken;
         }
      }

      return $.ajax(options);
   }

   var BASE_OPTIONS = {
         type: 'get',
         dataType: 'json'
      },
      JSON_OPTIONS = _.extend({}, {
         contentType: 'application/json; charset=utf-8'
      }, BASE_OPTIONS),
      DEFAULT_OPTIONS = {
         get: _.clone(BASE_OPTIONS),
         put: _.extend({}, JSON_OPTIONS, { type: 'put' }),
         post: _.extend({}, JSON_OPTIONS, { type: 'post' }),
         'delete': _.extend({}, JSON_OPTIONS, { type: 'delete' }),
         patch: _.extend({}, JSON_OPTIONS, { type: 'patch' })
      };

   return {
      doGet: function(options) {
         return sendRequest(_.extend({}, DEFAULT_OPTIONS.get, options));
      },

      doPut: function(options) {
         return sendRequest(_.extend({}, DEFAULT_OPTIONS.put, options));
      },

      doPost: function(options) {
         return sendRequest(_.extend({}, DEFAULT_OPTIONS.post, options));
      },

      doDelete: function(options) {
         return sendRequest(_.extend({}, DEFAULT_OPTIONS['delete'], options));
      },

      doPatch: function(options) {
         return sendRequest(_.extend({}, DEFAULT_OPTIONS.patch, options));
      }
   };
});
